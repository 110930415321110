<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="nuevo" v-if="permisos.add">Agregar</el-dropdown-item>
              <el-dropdown-item command="cambiar" v-if="permisos.edit">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar</el-dropdown-item>
              <el-dropdown-item command="sube" v-if="permisos.add">Subir Masivos</el-dropdown-item>
              <el-dropdown-item command="subeApp" v-if="permisos.up">Subir AppPromotor</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      <p>Ingrese Nombre</p>
      <el-input
        placeholder="Nombre"
        v-model="findName" size="mini">
      </el-input>
       
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Buscar Usuario
          </el-button>
        </div>
      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            class="elevation-1"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @click:row="eventRow"
          >
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
      </div>
    </div>

    <!-- modals-->

    <el-dialog
      title="Agregar Usuario"
      :visible.sync="dgNuevo"
      width="50%"
      top="20px">
      <div data-app>
          <v-form ref="nwClient" v-model="nuevoValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-6 pr-2">
            <v-text-field v-model="clNuevo.username" :rules="nameRules" label="Username" required ></v-text-field>
            </div>
            <div class="col-6 pl-2">
              <v-text-field v-model="clNuevo.password" :rules="folioRules" label="Password" required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4 pr-2">
            <v-text-field v-model="clNuevo.noEmpleado" :rules="nameRules" label="Número de Empleado" required ></v-text-field>
            </div>
            <div class="col-8 pl-2">
              <v-text-field v-model="clNuevo.nombre" :rules="folioRules" label="Nombre Completo" required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4 pr-2">
              <v-select v-model="clNuevo.sexo" :items="itemsSexo"
                :rules="[v => !!v || 'Seleccione una Opción']"
                label="Sexo"
                item-text="d"
                item-value="i"
                required
              ></v-select>
            </div>
            <div class="col-4 pr-2">
              <v-select v-model="clNuevo.tipo" :items="itemsLevels"
                :rules="[v => !!v || 'Seleccione un Tipo']"
                item-text="d"
                item-value="i"
                label="Tipo"
                required
              ></v-select>
            </div>
            <div class="col-4 pl-2">
              <v-select v-model="clNuevo.clase" :items="clases"
                :rules="[v => !!v || 'Seleccione una Opción']"
                item-value="i"
                item-text="d"
                label="Sub Clasificación"
                required
              ></v-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4 pr-2">
              <v-select v-model="clNuevo.region" :items="itemsRegiones"
                :rules="[v => !!v || 'Seleccione una Región']"
                item-value="i"
                item-text="d"
                label="Región"
                required
                @input="getGerencias(0)"
              ></v-select>
            </div>
            <div class="col-4 px-2">
              <v-select v-model="clNuevo.gerencia" :items="itemsGerencias"
                :rules="[v => !!v || 'Seleccione una Gerencia']"
                label="Gerencia"
                item-value="i"
                item-text="d"
                required
                @input="getCedis(0)"
                :disabled="itemsGerencias.length == 0"
              ></v-select>
            </div>
            <div class="col-4 pl-2">
              <v-select v-model="clNuevo.cedis" :items="itemsCedis"
                :rules="[v => !!v || 'Seleccione un Cedis']"
                label="Cedis"
                item-value="i"
                item-text="d"
                required
                :disabled="itemsCedis.length == 0"
              ></v-select>
            </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="addUser">Enviar Información</el-button>
                </div>
              </div>
      </v-form>
      </div>
    </el-dialog>
    <el-dialog
          :title="currentUser"
          :visible.sync="dgDetalle"
          top="20px">
          <!--<span  v-if="!clEdit.activo">{{clEdit.etAct}}</span><br>
        <base-switch v-model="clEdit.activo" @input="activarUsuario" v-if="!clEdit.activo"></base-switch>-->
      <div data-app>
          <v-form ref="nwEdit" v-model="nuevoValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-6 pr-2">
            <v-text-field v-model="clEdit.username" :rules="nameRules" label="Username" required ></v-text-field>
            </div>
            <div class="col-6 pl-2">
              <v-text-field v-model="clEdit.password" :rules="folioRules" label="Password" required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4 pr-2">
            <v-text-field v-model="clEdit.noEmpleado" :rules="nameRules" label="Número de Empleado" required ></v-text-field>
            </div>
            <div class="col-8 pl-2">
              <v-text-field v-model="clEdit.nombre" :rules="folioRules" label="Nombre Completo" required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4 pr-2">
              <v-select v-model="clEdit.sexo" :items="itemsSexo"
                :rules="[v => !!v || 'Seleccione una opcion']"
                label="Sexo"
                item-value="i"
                item-text="d"
                required
              ></v-select>
            </div>
            <div class="col-4 pr-2">
              <v-select v-model="clEdit.tipo" :items="itemsLevels"
                :rules="[v => !!v || 'Seleccione un Tipo']"
                item-text="d"
                item-value="i"
                label="Tipo"
                required
              ></v-select>
            </div>
            <div class="col-4 pl-2">
              <v-select v-model="clEdit.clase" :items="clases"
                :rules="[v => !!v || 'Seleccione una Opción']"
                item-value="i"
                item-text="d"
                label="Sub Clasificación"
                required
              ></v-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4 pr-2">
              <v-select v-model="clEdit.region" :items="itemsRegiones"
                :rules="[v => !!v || 'Seleccione una Región']"
                item-value="i"
                item-text="d"
                label="Región"
                required
                @input="getGerencias(3)"
              ></v-select>
            </div>
            <div class="col-4 px-2">
              <v-select v-model="clEdit.gerencia" :items="itemsGerencias"
                :rules="[v => !!v || 'Seleccione una Gerencia']"
                label="Gerencia"
                item-value="i"
                item-text="d"
                required
                @input="getCedis(3)"
                :disabled="itemsGerencias.length == 0"
              ></v-select>
            </div>
            <div class="col-4 pl-2">
              <v-select v-model="clEdit.cedis" :items="itemsCedis"
                :rules="[v => !!v || 'Seleccione un Cedis']"
                label="Cedis"
                item-value="i"
                item-text="d"
                required
                :disabled="itemsCedis.length == 0"
              ></v-select>
            </div>
          </div>
          <div class="row">
            <div class="col-4 pr-2 text-center">
              <el-button v-if="clEdit.activo" size="small" type="danger" @click="dgEliminar = !dgEliminar">Eliminar Usuario  <i class="tim-icons icon-trash-simple"></i></el-button>
              <el-button v-if="!clEdit.activo" size="small" type="success" @click="activarUser">Activar Usuario  <i class="tim-icons icon-check-2"></i></el-button>
            </div>
            <div class="col-4 pa-2 text-center">
              <el-button v-if="clEdit.activo" size="small" type="warning" @click="dgAsignar = !dgAsignar">Asignar Ruta  <i class="tim-icons icon-compass-05"></i></el-button>
            </div>
            <div class="col-4 pa-2 text-center">
              <el-button v-if="clEdit.activo" size="small" type="primary" @click="actualizaUser">Guardar Cambios <i class="tim-icons icon-badge"></i></el-button>
            </div>
          </div>
      </v-form>
      </div>
    </el-dialog>
    <el-dialog
       title="Subir Usuarios Masivos"
       :visible.sync="dgSubir"
       width="30%">
                <form class="form-horizontal" ref="formSubir">
                  <div class="row">
                    <label class="col-md-3 col-form-label">Región</label>
                    <div class="col-md-9">
                    <el-select v-model="mdRegion" placeholder="REGIONES" size="mini" @change="getGerencias(2)">
                        <el-option
                          v-for="item in mdRegiones"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Gerencia</label>
                    <div class="col-md-9">
                      <el-select v-model="mdGerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(2)">
                        <el-option
                          v-for="item in mdGerencias"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Cedis</label>
                    <div class="col-md-9">
                    <el-select v-model="mdCedi" placeholder="CEDIS" size="mini">
                      <el-option
                        v-for="item in mdCedis"
                        :key="item.i"
                        :label="item.d"
                        :value="item.i">
                      </el-option>
                    </el-select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-3 col-form-label">Archivo</label>
                    <div class="col-md-9">
                      <el-upload
                        class="upload-demo"
                        :auto-upload="false"
                        :on-change="subirArchivo">
                        <el-button size="small" type="primary">Clic para abrir archivo</el-button>
                        <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
                      </el-upload>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 offset-4 mt-3">
                      <el-button type="info" size="mini" @click="subirUsuarios">Enviar Información</el-button>
                    </div>
                  </div>
                </form>
    </el-dialog>
    <el-dialog
       title="Cambios Masivos"
       :visible.sync="dgCambios"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         :on-change="masivoArchivo"
         :auto-upload="false"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>
       <span slot="footer" class="dialog-footer">
         <el-button @click="dgCambios = false">Cancelar</el-button>
         <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
       </span>
    </el-dialog>
    <el-dialog
       title="Subir App Promotor"
       :visible.sync="dgPromotor"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         :on-change="masivoAppPromotor"
         :auto-upload="false"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <!--<el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>-->
       <span slot="footer" class="dialog-footer">
         <el-button @click="dgPromotor = false">Cancelar</el-button>
         <el-button type="primary" @click="upAppPromotor">Enviar</el-button>
       </span>
    </el-dialog>
    <el-dialog
      title="Asignar Ruta"
      :visible.sync="dgAsignar"
      width="30%">
        
          <!--<v-select v-model="clEdit.ruta" :items="itemsRutas"
            :rules="[v => !!v || 'Seleccione una Ruta']"
            label="Rutas"
            item-value="i"
            item-text="d"
            required
          ></v-select>
          <el-select v-model="clEdit.ruta" placeholder="Rutas" class="pb-4">
            <el-option
              v-for="item in itemsRutas"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>-->
          <v-text-field v-model="clEdit.ruta" :rules="rutaRules" label="Ruta" required ></v-text-field>
          <div class="text-right">
            <el-button @click="dgAsignar = false">Cancelar</el-button>
            <el-button type="primary" @click="asignarRuta">Asignar</el-button>
          </div>
        
    </el-dialog>
    <el-dialog
      title="Eliminar Usuario"
      :visible.sync="dgEliminar"
      width="30%">
      <p>Contraseña</p>
      <el-input v-model="passEliminar" placeholder="Contraseña"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgEliminar = false">Cancelar</el-button>
        <el-button type="primary" @click="validateEliminar">Confirmar</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Descargar Usuarios"
      :visible.sync="dgDescargar"
      width="30%">
      <p>Contraseña</p>
      <el-input v-model="passDescarga" placeholder="Contraseña"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgDescargar = false">Cancelar</el-button>
        <el-button type="primary" @click="descargaFiltro">Confirmar</el-button>
      </span>
    </el-dialog>

    <!-- end modals-->
    <v-overlay :value="overlay" z-index="2005">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");
//import { BaseSwitch } from 'src/components';

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      findName: '',
      desserts: [],
      headers: [
        {
          text: 'User ID',
          align: 'start',
          sortable: false,
          value: 'user_id',
        },
        { text: 'Username', value: 'user_username', width: 95, sortable: false },
        { text: 'Num. Empleado', value: 'user_numero_empleado', width: 100 },
        { text: 'Cedi Asignada', value: 'user_cds_temp', width: 200, sortable: false },
        { text: 'Ruta Asignada', value: 'user_rt', width: 100, sortable: false },
        { text: 'Password', value: 'user_password', width: 100 },
        { text: 'Nombre completo', value: 'user_nombre_completo', width: 250, sortable: false },
        { text: 'Tipo', value: 'user_level', width: 200, sortable: false },
        { text: 'Sub Categoria', value: 'user_subca', width: 130, sortable: false },
        { text: 'Sexo', value: 'user_sexo', width: 100, sortable: false },
        { text: 'Creado por', value: 'user_create_user_id', width: 250, sortable: false },
        { text: 'Activado?', value: 'user_activo', width: 100, sortable: false }
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgNuevo: false,
      clNuevo: {
        username: '',
        password: '',
        noEmpleado: '',
        nombre: '',
        sexo: '',
        clase: '',
        tipo: '',
        region: '',
        gerencia: '',
        cedis: ''
      },
      clEdit: {
        id: '',
        username: '',
        password: '',
        noEmpleado: '',
        nombre: '',
        sexo: '',
        clase: '',
        tipo: '',
        region: '',
        gerencia: '',
        cedis: '',
        activo: null,
        ruta:''
      },
      nuevoValid: true,
      nameRules: [
        v => !!v || 'Nombre Requerido'
      ],
      folioRules: [
        v => !!v || 'Folio Requerido'
      ],
      phoneRules: [
        v => (v && v.length >= 10) || 'Escriba un Número valido',
      ],
      rutaRules: [
        v => !!v || 'Ruta Requerida'
      ],
      clases: [
        {i: 2, d: 'INTERNO'},
        {i: 1, d: 'EXTERNO'},
        {i: 0, d: 'BECARIO'}
      ],
      dgDetalle: false,
      dgEliminar: false,
      passEliminar: '',
      dgDescargar: false,
      passDescarga: '',
      dgCambios: false,
      dgAsignar: false,
      dgSubir: false,
      dgPromotor: false,
      //nwRegion: '',
      itemsRegiones: [],
      itemsGerencias: [],
      itemsCedis: [],
      itemsRutas: [],
      resultadoMasivo: '',
      callCenter: '',
      itemsCallCenter: [],
      masvisoFileXls: null,
      permisos: {},
      itemsLevels: [],
      itemsSexo: [
        {i: 1, d: 'Masculino'},
        {i: 0, d: 'Femenino'}
      ],
      currentUser: '',
      mdRegiones: [],
      mdGerencias: [],
      mdCedis: [],
      mdRegion: '',
      mdGerencia: '',
      mdCedi: '',
      mdFileXls: '',
      masivoPromotor: '',
      disGerNuevo: true,
      disCedNuevo: true
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'nuevo':
          this.dgNuevo = true
          if(this.$refs.nwClient){
            this.$refs.nwClient.reset()
          }
        break
        case 'descarga':
          this.dgDescargar = true
          this.passDescarga = null
        break
        case 'sube':
          this.dgSubir = true

        break
        case 'subeApp':
          this.dgPromotor = true

        break
        case 'filtrar':
          this.dgFiltrar = true
        break
        case 'cambiar':
          this.dgCambios = true
        break
        case 'asignar':
          this.getCallCenters()
          this.dgAsignar = true
        break
      }
    },
    rowClass(item) {
      // console.log(item);
      if(item.valCar_val_status == 'Capturado' && item.valCar_gepp_rev == 'NO VALIDADO') { return 'table-primary' }
      if(item.valCar_val_status == 'Capturado' && item.valCar_gepp_rev == 'VALIDADO') { return 'table-info' }
      if(item.valCar_val_status == 'Rechazado' ) { return 'table-danger' }
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getUsers()
    },
    filtrar() {
      this.getUsers()
    },
    getUsers() {
      //console.log(this.pre.join(','))
      this.overlay = true
      let json ={
        c: "users",
        cmd: "GetUsersGridData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        level: this.lvl,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        first: this.findName != "" ? 0 : '',
        textP: this.findName != "" ? this.findName : ''
      };


      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data);
        var resp = data.data.rows
        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        this.overlay = false
        //this.pagination.rowsTotal = data.data.results
        //this.isBusy = false
      })
    },
    getRegiones(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        if(typeof(data.data.success) != "undefined" && !data.data.success){
            localStorage.clear()
            this.$router.push('login')
        }else{
          this.regiones = data.data
          this.itemsRegiones = data.data
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.itemsRegiones.splice(0,1)

          this.mdRegiones = data.data
        }
        
      })
    },
    getGerencias(sc){

     /* if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }
*/
      var region = 0
        if(sc == 0){
          region = this.clNuevo.region
          if(region == -1){
            this.clNuevo.gerencia = null
            this.itemsGerencias = []
            return
          }
        }else if(sc == 2){
          region = this.mdRegion
        }else if(sc == 3){
          region = this.clEdit.region
          if(region == -1){
            this.clEdit.gerencia = null
            this.itemsGerencias = []
            return
          }          
        }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: region
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.itemsGerencias = data.data
          if (region != -1) this.itemsGerencias.unshift({ i: -1, d: 'TODAS' })
        }else if(sc == 2){
          this.mdGerencias = data.data
        }else if(sc == 3){
          this.itemsGerencias = data.data
           if (region != -1) this.itemsGerencias.unshift({ i: -1, d: 'TODAS' })
        }
      })
    },
    getCedis(sc){
      var response = new Promise (resolve =>{

          var gerencia = 0
          if(sc == 0){ 
            gerencia = this.clNuevo.gerencia
            if(gerencia == -1){
                this.clNuevo.cedis = null
                this.itemsCedis = []
                return
              }
          }else if(sc == 2){
            gerencia = this.mdGerencia
          }else if(sc == 3){
            gerencia = this.clEdit.gerencia
            if(gerencia == -1){
              this.clNuevo.cedis = null
              this.itemsCedis = []
              return
            }
          }

        let json ={
          c: "geppValida",
          cmd: "getCedisJson",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          ger: gerencia
        };

        axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
        .then(data=>{
          //console.log(data.data);
          if(sc == 0){
            this.itemsCedis = data.data
            if(gerencia !== -1) this.itemsCedis.unshift({ i: -1, d: 'TODOS' })
            resolve(true)
          }else if(sc == 2){
            this.mdCedis = data.data
          }else if(sc == 3){
            this.itemsCedis = data.data
            if(gerencia !== -1) this.itemsCedis.unshift({ i: -1, d: 'TODOS' })
            resolve(true)
          }
        })
      })
      return response
    },
    getPreventa(cdi){
      //this.getUsuarios()
      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: cdi
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
          this.itemsRutas = data.data
      })
    },
    getUsuarios(){
      let json ={
        c: "geppValida",
        cmd: "getUserJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data.data);
        this.usuarios = data.data
        this.usuarios.unshift({ i: -1, d: 'TODOS' })
      })
    },
    descargaFiltro(){
      if(this.passDescarga == '#admin'){
        this.dgDescargar = false
        window.location = "https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=users&cmd=downloadUsers&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl;
      }else{
        this.$notify({
          title: 'Error',
          message: 'Contraseña Incorrecta verifique y vuelva a intentar.',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 4000,
          icon: 'tim-icons icon-bell-55'
        });
      }
    },
    filtrarRegistros(){
      this.getUsers()
    },
    verificaTel(){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "CarteraFin",
          cmd: "verifiTel",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          Tel: this.clNuevo.phone
        };

        axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
        .then(data=>{
          console.log(data.data);
          if(data.data.success) {
            resolve(true)
          }else {
            resolve(false)
          }
        })
      })
      return respuesta
    },
    addUser(){
      this.overlay = true
     // if(this.$refs.nwClient.validate()){
          let json = {
            c: "users",
            cmd: "add",
            exec: "self",
            uid: this.uid,
            hash: this.hash,
            new_user_username: this.clNuevo.username,
            new_user_password: this.clNuevo.password,
            new_user_numero_empleado: this.clNuevo.noEmpleado,
            new_user_nombre: this.clNuevo.nombre,
            new_user_sexo: this.clNuevo.sexo,
            new_user_subcla: this.clNuevo.clase,
            new_user_level: this.clNuevo.tipo,
            new_user_region: this.clNuevo.region,
            new_user_gerencia: this.clNuevo.gerencia,
            new_user_cedis: this.clNuevo.cedis
          }
              // POST
            const queryString = new URLSearchParams(json).toString();
            axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?" + queryString)
            
            /*?c=CarteraFin&cmd=validacionInsert&exec=self"
                        +"&hash="+this.hash+"&uid="+this.uid, JSON.stringify(json))*/
            .then(data=>{
              //console.log(data.data);
              
              this.overlay = false
              this.getUsers()
              if(data.data.success) {
                this.dgNuevo = false
                this.getUsers()
                  this.$notify({
                    title: '',
                    message: 'Usuario Registrado con éxito.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success',
                    timeout: 10000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }else {
                  this.$notify({
                    title: 'Error',
                    message: data.data.msg,//'El Usuario no se registro de forma correcta.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'warning',
                    timeout: 4000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }
            })
     // }
    },
    actualizaUser(){
      this.overlay = true
      if(this.$refs.nwEdit.validate()){
          let json = {
            c: "users",
            cmd: "capturaManual10",
            exec: "self",
            uid: this.uid,
            hash: this.hash,
            v_cns_id: this.clEdit.id,
            user: this.clEdit.username,
            pass: this.clEdit.password,
            nEmpleado: this.clEdit.noEmpleado,
            nombre: this.clEdit.nombre,
            sexo: this.clEdit.sexo,
            subcla: this.clEdit.clase,
            level: this.clEdit.tipo,
            region: this.clEdit.region,
            gerencia: this.clEdit.gerencia,
            cedis: this.clEdit.cedis
          }
              // POST
            const queryString = new URLSearchParams(json).toString();
            axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?" + queryString)
            
            /*?c=CarteraFin&cmd=validacionInsert&exec=self"
                        +"&hash="+this.hash+"&uid="+this.uid, JSON.stringify(json))*/
            .then(data=>{
              console.log(data.data);
              this.overlay = false
              this.dgDetalle = false
              if(data.data.success) {
                this.getUsers()
                  this.$notify({
                    title: '',
                    message: 'Usuario Actualizado con éxito.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success',
                    timeout: 4000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }else {
                  this.$notify({
                    title: 'Error',
                    message: data.data.msg,
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'warning',
                    timeout: 8000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }
            })
      }
    },
    cambiosMasivos(){

      this.overlay = true

      const formData = new FormData();
      formData.append('wnd_upld_xls', this.masvisoFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraFin&cmd=massiveUpdate&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{

        this.overlay = false
        if(data.data.success){
            //this.$refs.upMasivos.reset()
            //this.resultadoMasivo = data.data.reg
            this.$notify({
              title: '',
              message: 'Se realizaron los cambios con éxito.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }else{
          this.$notify({
            title: 'Error',
            message: 'Hay un error con el archivo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      })
    },
    upAppPromotor(){

      this.overlay = true

      const formData = new FormData();
      formData.append('wnd_upld_xls', this.masivoPromotor);
      formData.append('wnd_uppr_act', 3);
      formData.append('wnd_uppr_uid', this.uid);

      axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraFin&cmd=uploadUsersAppPromotor&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
        this.overlay = false
        if(data.data.success){
            //this.$refs.upMasivos.reset()
            //this.resultadoMasivo = data.data.reg
            this.$notify({
              title: '',
              message: 'Se realizaron los cambios con éxito.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }else{
          this.$notify({
            title: 'Error',
            message: 'Hay un error con el archivo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      })
    },
    masivoArchivo(file){
      this.masvisoFileXls = file.raw
    },
    masivoAppPromotor(file){
      this.masivoPromotor = file.raw
    },
    eliminarUs(){
      //this.dgEliminar = true
      let json ={
        c: "users",
        cmd: "delete",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        rows: {
          user_id: this.clEdit.id
        }
      };
     axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        this.getUsers()
          this.$notify({
            title: '',
            message: 'Se elimino el usuario éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    validateEliminar(){
      if(this.passEliminar == '#admin'){
        this.dgEliminar = false
        this.dgDetalle = false
        this.passEliminar = ''
        this.eliminarUs()
        //this.getUsers()
        // this.dgEliminarReg = true
      }else{
        //
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    async eventRow(record){
      console.log(this.clases);
      this.getLevels()
      this.clEdit.id = record.user_id
      this.clEdit.activo = record.user_activo == 'Activado' ? true :  false
      // this.clEdit.etAct = record.user_activo
      this.dgDetalle = true
      this.currentUser = record.user_nombre_completo
      this.clEdit.username = record.user_username
      this.clEdit.password = record.user_password
      this.clEdit.noEmpleado = record.user_numero_empleado
      this.clEdit.nombre = record.user_nombre_completo
      this.clEdit.sexo = this.itemsSexo.find(sx => sx.d == record.user_sexo).i 
      this.clEdit.tipo = this.itemsLevels.find(l => l.i == record.user_level_id).i //record.user_level_id
      this.clEdit.clase = this.clases.find(cl => cl.d === record.user_subca).i
      this.clEdit.region = record.user_reg // { i: record.user_reg, d: record.user_reg_temp }
      this.getGerencias(1)
      this.clEdit.gerencia = record.user_ger // { i: record.user_ger, d: record.user_ger_temp }
      await this.getCedis(1)
      var cds = this.itemsCedis.find(cd => cd.d === record.user_cds_temp)
      this.clEdit.cedis = cds.i//{ i: record.user_cds, d: record.user_cds_temp }
      this.getPreventa(cds.i)

    },
    activarUser(){
      let json ={
        c: "users",
        cmd: "ActiveUsers",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        userId: this.clEdit.id
      };
     axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data.data);
        this.getUsers()
        this.dgDetalle = false
          this.$notify({
            title: '',
            message: 'Se activo el usuario éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    asignarRuta(){
      let json ={
        c: "users",
        cmd: "capturaManualRutas",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        v_cns_id: this.clEdit.id,
        rutas: this.clEdit.ruta
      };
     axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data.data);
        this.getUsers()
        this.dgDetalle = false
          this.$notify({
            title: '',
            message: 'Se activo el usuario éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    getLevels(){
      let json ={
        c: "users",
        cmd: "getUserAllowLevels",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: 0,
        lvl: this.lvl
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data.data);
        this.itemsLevels = data.data
      })
    },
    subirArchivo(file){
      //console.log(file)
      this.mdFileXls = file.raw
    },
    subirUsuarios(){

      this.overlay = true

      const formData = new FormData();
      formData.append('frm_ini_xls', this.mdFileXls);
      formData.append('frm_ini_act', 0);

      axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraIni&cmd=processFormUsers&exec=self&hash="+this.hash+"&uid="+this.uid+"&u_reg="+this.mdRegion+"&ger="+this.mdGerencia+"&cds="+this.mdCedis+"&opr=0", formData)
      .then(data=>{
        //console.log(data.data);
        this.$notify({
            title: '',
            message: 'Usuarios cargados con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    }
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

      this.permisos = JSON.parse(localStorage.getItem('permission')).admin.actions

      console.log(this.permisos);

      this.getRegiones()
      this.getUsers()
      this.getLevels()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}


.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   /*font-size: 11px !important;
   height: 20px;*/
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 500px;
    width: 100%;
  }


</style>