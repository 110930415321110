<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga">Descargar Filtro</el-dropdown-item>
              <el-dropdown-item command="sube">Subir Clientes</el-dropdown-item>
              <el-dropdown-item command="cambia">Cambios Masivos</el-dropdown-item>
              <!--<el-dropdown-item command="cambiar">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="asignar">Re Asignar</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
               
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            class="elevation-1"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @click:row="eventRow"
          >
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
      </div>
    </div>

    <!-- modals-->

    <el-dialog
       title="Cambios Masivos"
       :visible.sync="fmCambios"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         :auto-upload="false"
         :on-change="masivoArchivo"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <!--<el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>-->
       <span slot="footer" class="dialog-footer">
         <el-button @click="fmCambios = false">Cancelar</el-button>
         <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
       </span>
     </el-dialog>
    <el-dialog
       title="Subir Clientes"
       :visible.sync="fmSubir"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         :on-change="subirArchivo"
         :auto-upload="false"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <!--<el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>-->
       <span slot="footer" class="dialog-footer">
         <el-button @click="fmSubir = false">Cancelar</el-button>
         <el-button type="primary" @click="subirClientesCsv">Enviar</el-button>
       </span>
     </el-dialog>
    <el-dialog
      title="Subir Clientes"
      :visible.sync="dgSubir"
      width="30%">
      <p>Contraseña</p>
      <el-input v-model="passSubir" placeholder="Contraseña"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgSubir = false">Cancelar</el-button>
        <el-button type="primary" @click="subirClientes">Confirmar</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Cambios Masivos"
      :visible.sync="dgCambios"
      width="30%">
      <p>Contraseña</p>
      <el-input v-model="passCambios" placeholder="Contraseña"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgCambios = false">Cancelar</el-button>
        <el-button type="primary" @click="confCambios">Confirmar</el-button>
      </span>
    </el-dialog>
    <!-- end modals-->
    <v-overlay :value="overlay" z-index="2005">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { API_KEY } from "../Maps/API_KEY";
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader(API_KEY);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
    dateTimePicker: [],
      desserts: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'captados_id',
        },
        { text: 'Región', value: 'captados_region', width: 100, sortable: false },
        { text: 'Gerencia', value: 'captados_gerencia', width: 150, sortable: false },
        { text: 'Cedis', value: 'captados_cedis', width: 200, sortable: false },
        { text: 'NUD', value: 'captados_nud', sortable: false },
        { text: 'Cliente', value: 'captados_cliente', width: 300, sortable: false },
        { text: 'Fecha', value: 'captados_fecha', sortable: false },
        { text: 'Teléfono 1', value: 'captados_tel1', sortable: false },
        { text: 'Teléfono 2', value: 'captados_tel2', sortable: false },
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgFiltrar: false,
      resultadoMasivo: '',
      masvisoFileXls: null,
      fmSubir: false,
      dgSubir: false,
      passSubir: '',
      subirFileXls: null,
      dgCambios: false,
      fmCambios: false,
      passCambios: ''
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'sube':
          this.dgSubir = true
        break
        case 'cambia':
          this.dgCambios = true
        break
      }
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    filtrar() {
      this.getData()
    },
    getData() {
      this.overlay = true
      //console.log(this.pre.join(','))
     // this.isBusy = true
      let json ={
        c: "carteraCaptada",
        cmd: "getCartIniData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        opr: 0,
        reg_id: this.region ? this.region : 0,
        ger_id: this.gerencia ? this.gerencia : 0,
        cds_id: this.cedi ? this.cedi : 0,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        date_fr: this.dateTimePicker[0] ? this.dateTimePicker[0] : null,
        date_to: this.dateTimePicker[1] ? this.dateTimePicker[1] : null
      };



      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data);
        var resp = data.data.rows
        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        this.overlay = false
      })
    },
    getRegiones(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        if(typeof(data.data.success) != "undefined" && !data.data.success){
            localStorage.clear()
            this.$router.push('login')
        }else{
          this.regiones = data.data
          //this.itemsRegiones = data.data
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.itemsRegiones.splice(0,1)
        }
          
        
      })
    },
    getGerencias(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : this.clNuevo.region ? this.clNuevo.region : null
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.itemsGerencias = data.data
        }
      })
    },
    getCedis(sc){
      if(this.gerencia == -1){
        this.cedi = null
        this.cedis = []
        return false
      }
      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : this.clNuevo.gerencia ? this.clNuevo.gerencia : null
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.itemsCedis = data.data
        }
      })
    },
    descargaFiltro(){
      var str = ''
      str += this.region ? "&reg="+this.region : ''
      str += this.gerencia ? "&ger="+this.gerencia : ''
      str += this.cedi ? "&cds="+this.cedi : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = "https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraCaptada&cmd=getCartCapDataDownload&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr=0"+str;
    },
    cambiosMasivos(){
      /*let json ={
        c: "carteraCaptada",
        cmd: "processForm",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar,
        wnd_upld_xls: this.masvisoFileXls,
        wnd_upld_act: 2,
        wnd_upld_uid: this.uid
      };*/
   this.overlay = true


      const formData = new FormData();
      formData.append('frm_ini_xls', this.masvisoFileXls);
      formData.append('frm_ini_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=carteraCaptada&cmd=processForm&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
        this.overlay = false
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    masivoArchivo(file){
      this.masvisoFileXls = file.raw
    },
    subirArchivo(file){
      this.subirFileXls = file.raw
    },
    subirClientes(){
      if(this.passSubir == '#admin'){
        this.dgSubir = false
        this.fmSubir = true
      }else{
        this.$notify({
          title: 'Error',
          message: 'Contraseña Incorrecta verifique y vuelva a intentar.',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 4000,
          icon: 'tim-icons icon-bell-55'
        });
      }
    },
    confCambios(){
      if(this.passCambios == '#admin'){
        this.dgCambios = false
        this.fmCambios = true
      }else{
        this.$notify({
          title: 'Error',
          message: 'Contraseña Incorrecta verifique y vuelva a intentar.',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 4000,
          icon: 'tim-icons icon-bell-55'
        });
      }
    },
    subirClientesCsv(){
      /*let json ={
        c: "carteraCaptada",
        cmd: "processForm",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        frm_ini_act: '0',
        frm_ini_xls: this.fileXls
      };*/
      this.overlay = true


      const formData = new FormData();
      formData.append('frm_ini_xls', this.subirFileXls);
      formData.append('frm_ini_act', 0);

      axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=carteraCaptada&cmd=processForm&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
        console.log(data.data);
        this.overlay = false
        this.$notify({
            title: '',
            message: 'Clientes cargados con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

    /* var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da*/

      this.getRegiones()
      this.getData()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}


.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   /*font-size: 11px !important;
   height: 20px;*/
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 500px;
    width: 100%;
  }

</style>