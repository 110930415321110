<template>
  <div class="container">
  <b-card no-body class="overflow-hidden center cc" style="max-width: 90%;">
    
    <b-row no-gutters>
      <b-col md="6">
        <div class="ccr">
          <img src="img/GEPP1.png" alt="" width="400px" />
        </div>
      </b-col>
      <b-col md="6">

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
              <div class="fLogin text-center">
                  <p>&nbsp;</p><p>&nbsp;</p>
                  <span class="" style="font-size:60px;color:#fff">CAPENH</span>
                  <h3 style="color:#fff">Captura Entrega Hogar</h3>
                  <div data-app>
                  <v-text-field
                    v-model="email"
                    prepend-inner-icon="mdi-account"
                    placeholder="Usuario"
                    color="info"
                    :dark="false"
                    filled
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    prepend-inner-icon="mdi-lock"
                    placeholder="Contraseña"
                    type="password"
                    filled
                  ></v-text-field>
                  </div>
                <div slot="footer">
                  <base-button native-type="submit" type="primary" style="color:#fff" class="mb-3" size="lg" block>
                    Ingresar
                  </base-button>
                    <base-alert type="danger" dismissible v-if="error">
                      <span>Usuario o Contraseña Incorrectos</span>
                    </base-alert>
                  <div class="label" style="color:#fff" >Powered by TMS, 2022</div>
                  <!--<div class="pull-left">
                    <h6>
                      <router-link class="link footer-link" to="/register">
                        Create Account
                      </router-link>
                    </h6>
                  </div>

                  <div class="pull-right">
                    <h6><a href="#pablo" class="link footer-link">Need Help?</a></h6>
                  </div>-->
                </div>
              </div>
            </form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-card>
  </div>
</template>
<script>

import { extend } from "vee-validate";
import { BaseAlert } from 'src/components';
import { required, email, min } from "vee-validate/dist/rules";
import axios from 'axios';

extend("emails", email);
extend("min", min);

extend("required", required);


export default {
  components: {
    BaseAlert
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      errorsc:["no sabes nada"],
      error: false
    };
  },
  methods: {
    submit() {
      
        this.error = false
      //alert("Form has been submitted!");
      let json ={
        c: "login",
        cmd: "login",
        exec: "self",
        user: this.email,
        pass: this.password
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{

          //console.log(data.data);
          localStorage.setItem('key',data.data.key)
          localStorage.setItem('uid',data.data.uid)
          localStorage.setItem('level',data.data.level)
          //this.$router.push('dashboard');
          this.validateLogin(data.data.uid, data.data.level, data.data.key)

      }).catch(e=>{
        //console.log(e);
          this.error = true
      })
    },
    validateLogin(uid, lvl, hash){
      let json ={
        c: "users",
        cmd: "validateLoginUser",
        exec: "self",
        uid: uid,
        level: lvl,
        hash: hash
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{

          //console.log(data.data.cartera_ini.actions);
          localStorage.setItem('permission', JSON.stringify(data.data))
          this.$router.push('capturados');

      }).catch(e=>{
        //console.log(e);
          this.error = true
      })
    },    
    toggleMode(type) {
        let docClasses = document.body.classList;
        if (type) {
          docClasses.remove('white-content');
        } else {
          docClasses.add('white-content');
        }
      }
  },
  mounted () {
    this.toggleMode(false)
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.azul_gepp {
  background-color: #124A98;
}

.col {
  padding-right: 0px;
  padding-left: 0px;
  
}

.fLogin {
  padding: 40px;
  background-color: #124A98;
  height: 550px;
}

.img {
  padding-bottom: 10px;
}
.cc {
  border-radius: 10px;
}

.ccr {
  background: #fff;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
    display: inline-block;
    margin-bottom: -30px;
    vertical-align: bottom;
}

.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #fff;
}

</style>
