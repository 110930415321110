<template>
    <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar Filtro</el-dropdown-item>
              <el-dropdown-item command="subir" v-if="permisos.add" >Subir Cartera</el-dropdown-item>
              <el-dropdown-item command="eliminar" v-if="permisos.del">Eliminar Registros</el-dropdown-item>
              <el-dropdown-item command="cambiar" v-if="permisos.edit">Cambios Masivos Cartera</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas != 0">Rutas:</span>
          <el-select v-if="preventas != 0" v-model="pre" placeholder="RUTAS" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Nuevo:
          <el-select v-model="nuevo" placeholder="NUEVO" size="mini">
            <el-option
              v-for="item in nuevos"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Source:
          <el-select v-model="source" placeholder="SOURCE" size="mini">
            <el-option
              v-for="item in sources"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>

      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="car_id"
            class="elevation-1"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @click:row="verRow"
          >
            <template v-slot:item.car_val_status="{ item }" >
              {{ item.car_val_status == 3 ? 'Capturado': item.car_val_status == 2 ? 'Rechazado' : item.car_val_status == 6 ? 'Pen. por Validar' : 'Por Revisar' }}
            </template>
            <template v-slot:item.car_foto1="{ item }" >
              {{ item.car_foto1 !== '' ? 'Sí' : 'No' }}
            </template>
            <template v-slot:item.car_gps="{ item }">
              {{ item.car_gps !== '' ? 'Sí': 'No' }}
            </template>
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
            <modal
              :show.sync="mdSubir"
              headerClasses="justify-content-center"
            >
              <h4 slot="header" class="title title-up">SUBIR CARTERA</h4>
              <!--action="#" method="#" @submit.prevent>-->
                <form class="form-horizontal" ref="formSubir">
                  <div class="row">
                    <label class="col-md-3 col-form-label">Región</label>
                    <div class="col-md-9">
                    <el-select v-model="mdRegion" placeholder="REGIONES" size="mini" @change="getGerencias(1)">
                        <el-option
                          v-for="item in mdRegiones"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Gerencia</label>
                    <div class="col-md-9">
                      <el-select v-model="mdGerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(1)">
                        <el-option
                          v-for="item in mdGerencias"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Cedis</label>
                    <div class="col-md-9">
                    <el-select v-model="mdCedi" placeholder="CEDIS" size="mini">
                      <el-option
                        v-for="item in mdCedis"
                        :key="item.i"
                        :label="item.d"
                        :value="item.i">
                      </el-option>
                    </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Busqueda</label>
                    <div class="col-md-9">
                    <el-select v-model="mdBuscar" placeholder="Buscar" size="mini" >
                      <el-option
                        label="SÍ"
                        value="0">
                      </el-option>
                      <el-option
                        label="NO"
                        value="1">
                      </el-option>
                    </el-select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-3 col-form-label">Archivo</label>
                    <div class="col-md-9">
                      <el-upload
                        class="upload-demo"
                        :on-change="subirArchivo">
                        <el-button size="small" type="primary">Clic para abrir archivo</el-button>
                        <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
                      </el-upload>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 offset-4 mt-3">
                      <el-button type="info" size="mini" @click="subirCartera">Enviar Información</el-button>
                    </div>
                  </div>
                </form>
            </modal>

            <el-dialog
              title="Subir cartera"
              :visible.sync="dgSubir"
              width="30%">
              <span>Por favor verifique que sus registros no existan, de lo contrario eliminelos antes de subir cartera.</span>
              <p>Contraseña</p>
              <el-input v-model="passSubir" placeholder="Contraseña"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgSubir = false">Cancelar</el-button>
                <el-button type="primary" @click="validateSubir">Confirmar</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="Eliminar Registros"
              :visible.sync="dgEliminar"
              width="30%">
              <p>Contraseña</p>
              <el-input v-model="passEliminar" placeholder="Contraseña"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgEliminar = false">Cancelar</el-button>
                <el-button type="primary" @click="validateEliminar">Confirmar</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="Eliminar Registros"
              :visible.sync="dgEliminarReg"
              width="30%">
              <p>Ingrese los Id a Eliminar</p>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="Ids separados por coma (,)"
                v-model="idsEliminar">
              </el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgEliminarReg = false">Cancelar</el-button>
                <el-button type="primary" @click="eliminarRegistros">Eliminar</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="Cambios Masivos"
              :visible.sync="dgMasivo"
              width="30%">
              <p>Contraseña</p>
              <el-input v-model="passMasivos" placeholder="Contraseña"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgMasivo = false">Cancelar</el-button>
                <el-button type="primary" @click="validateMasivo">Confirmar</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="Cambios Masivos"
              :visible.sync="dgCambio"
              width="30%">
              <el-upload
                class="upload-demo mb-3"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="masivoArchivo"
                >
                <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
                <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
              </el-upload>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="Resultados"
                v-model="resultadoMasivo">
              </el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgCambio = false">Cancelar</el-button>
                <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
              </span>
            </el-dialog>
            <el-dialog
              :title="currentClient"
              :visible.sync="dgDetalle"
              width="60%">
                <div class="row">
                    <label class="col-md-6 col-form-label">
                      <img :src="currentImagen" alt="" width="80%"/>
                    </label>
                      <div class="col-md-6">
                        <card type="plain">
                          <div id="customSkinMap" class="map"></div>
                        </card>
                      </div>
                </div>
            </el-dialog>
          </div>
          <!---->
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    </div>
</template>

<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog } from 'element-ui';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import axios from 'axios';
import { Modal } from 'src/components';
// import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";

const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
      Modal,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      overlay: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'car_id',
        },
        { text: 'Estatus Validación', value: 'car_val_status', width: 150, sortable: false },
        { text: 'Nuevo', value: 'car_nuevo', sortable: false },
        // { text: 'Operación', value: 'car_oper', width: 150, sortable: false },
        { text: 'Región', value: 'car_reg', width: 150, sortable: false },
        { text: 'Gerencia', value: 'car_gerencia', width: 150, sortable: false },
        { text: 'Cedis', value: 'car_cds', width: 200, sortable: false },
        { text: 'Ruta Preventa', value: 'car_rt_prev', width: 150, sortable: false },
        { text: 'Source', value: 'car_sourse', width: 150, sortable: false },
        { text: 'Estatus Abrio', value: 'valCar_status_abrio', sortable: false },
        { text: 'Garrafón Marca', value: 'valCar_pro_compra', sortable: false },
        { text: 'Marca', value: 'valCar_pro_marca', sortable: false },
        { text: 'Garrafones Semana', value: 'valCar_pro_cuantos', sortable: false },
        { text: 'Esta Interesado', value: 'valCar_pro_interesado', sortable: false },
        { text: 'Nombre', value: 'car_nombre', width: 300, sortable: false },
        { text: 'Dirección', value: 'car_dir', width: 250, sortable: false },
        { text: 'Num Ext', value: 'car_num_ext', sortable: false },
        { text: 'Colonia', value: 'car_col', width: 250, },
        { text: 'C.P', value: 'car_cp', sortable: false },
        { text: 'Teléfono', value: 'car_tel', sortable: false },
        { text: 'Teléfono2', value: 'car_tel_2', sortable: false },
        { text: 'Comentario', value: 'car_comments', width: 250, sortable: false },
        { text: 'Foto', value: 'car_foto1', sortable: false },
        { text: 'GPS', value: 'car_gps', sortable: false },
        { text: 'Versión', value: 'car_ver', sortable: false },
        { text: 'Fecha Envío', value: 'car_upload_date', sortable: false },
        { text: 'Hora Envío', value: 'car_upload_time', sortable: false },
        ],
      desserts: [],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      isBusy: false,
      region: null,
      regiones: [],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      pre: null,
      preventas: [
        ],
      nuevo: null,
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      source: null,
      sources: [
      { i: -1, d: 'Todos' },
      { i: 0, d: 'App' },
      { i: 1, d: 'Facebook' },
      { i: 2, d: 'Web' },
      { i: 3, d: 'Portal' },
      { i: 4, d: 'Call Center' },
      { i: 5, d: 'Whats' },
      { i: 6, d: 'Transfer' },
      { i: 7, d: 'Lona' },
      { i: 8, d: 'App Promotor' },
      { i: 9, d: 'Socios Comerciales' },
      { i: 10, d: 'Campaña Digital' }
    ],
    dateTimePicker: [],
    hash: '',
    uid: '',
    mdSubir: false,
    mdFileXls: null,
    mdBuscar: '',
    mdRegion: null,
    mdRegiones: [],
    mdGerencia: null,
    mdGerencias: [],
    mdCedi: null,
    mdCedis: [],
    dgSubir: false,
    passSubir: null,
    dgEliminar: false,
    dgEliminarReg: false,
    passEliminar: '',
    idsEliminar: '',
    dgMasivo: false,
    passMasivos: '',
    dgCambio: false,
    resultadoMasivo: '',
    masvisoFileXls: null,
    dgDetalle: false,
    currentClient: '',
    currentImagen: '',
    permisos: {}
    };
  },
  methods: {
    rowClass(item) {
      //if (!item) return
      if (item.car_val_status == '3') return 'table-primary'
      if (item.car_val_status == '2') return 'table-danger'
    },
    paginate(p) {
      console.log(p);
      this.pagination.page = p

      this.pagination.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      //this.isBusy = true
      this.overlay = true
      let json ={
        c: "carteraIni",
        cmd: "getCartIniData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        opr_id: 1,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        rta_id: this.pre ? this.pre.join(',') : '',
        nue_id: this.nuevo,
        src_id: this.source,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data);
        this.overlay = false
        var resp = data.data.rows
        //this.items = resp;
        //this.pagination.rowsTotal = data.data.results

        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        //this.overlay = false
        //this.isBusy = false
      })
    },
    getRegiones(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        if(typeof(data.data.success) != "undefined" && !data.data.success){
            localStorage.clear()
            this.$router.push('login')
        }else{
          this.regiones = data.data
          //this.mdRegiones = data.data
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.mdRegiones.splice(0,1)
        }
        
      })
    },
    getRegionesMd(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
          this.mdRegiones = data.data
          //this.mdRegiones.splice(0,1)
      })
    },
    getGerencias(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : this.mdRegion ? this.mdRegion : null
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.mdGerencias = data.data
        }
      })
    },
    getCedis(sc){

      if(this.gerencia == -1){
        this.cedi = null
        this.pre = null
        this.cedis = []
        this.preventas = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : this.mdGerencia ? this.mdGerencia : null
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.mdCedis = data.data
        }
      })
    },
    getPreventa(){
      if(this.cedi == -1){
        this.pre = null
        this.preventas = []

        return false
      }
      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data.data);
        this.preventas = data.data
        this.preventas.unshift({ i: -1, d: 'TODAS' })
      })
    },
    filtrar(){
      this.getData()
    },
    descargaFiltro(){
      var str = ''

      str += this.region ? "&u_reg="+this.region : ''
      str += this.gerencia ? "&ger="+this.gerencia : ''
      str += this.cedi ? "&cds="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : ''
      str += this.nuevo ? "&nue_id="+this.nuevo : ''
      str += this.source ? "&src_id="+this.source : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = "https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraIni&cmd=getCartIniDataDownload&exec=self&uid="+this.uid+"&hash="+this.hash+"&opr=1"+str;
    },
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'subir':
          this.dgSubir = true
          this.getRegionesMd()
        break
        case 'eliminar':
          this.dgEliminar = true
        break
        case 'cambiar':
          this.dgMasivo = true
        break
      }
    },
    validateSubir(){
      if(this.passSubir == '#admin'){
        this.dgSubir = false
        this.passSubir = ''
        this.mdSubir = true
      }else{
        //
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    validateEliminar(){
      if(this.passEliminar == '#admin'){
        this.dgEliminar = false
        this.passEliminar = ''
        this.dgEliminarReg = true
      }else{
        //
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    validateMasivo(){
      if(this.passMasivos == '#admin'){
        this.dgMasivo = false
        this.passMasivos = ''
        this.dgCambio = true
      }else{
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    subirArchivo(file){
      //console.log(file)
      this.mdFileXls = file
    },
    masivoArchivo(){
      this.masvisoFileXls = file
    },
    subirCartera(){
      let json ={
        c: "CarteraIni",
        cmd: "processForm",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        frm_ini_act: '0',
        frm_ini_bisqueda: this.mdBuscar,
        frm_ini_xls: this.fileXls,
        u_reg: this.mdRegion,
        ger: this.mdGerencia,
        cds: this.mdCedi
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data.data);
        this.$notify({
            title: '',
            message: 'Cartera cargada con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    eliminarRegistros(){
      let json ={
        c: "CarteraFin",
        cmd: "getUpEliminarCartera",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Clientes Eliminados con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    cambiosMasivos(){
      let json ={
        c: "CarteraFin_all",
        cmd: "processFormCartera",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar,
        wnd_upld_xls: this.masvisoFileXls,
        wnd_upld_act: 2,
        wnd_upld_uid: this.uid
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    verRow(record, idx){
      console.log(record);
      this.currentClient = record.car_nombre
      this.dgDetalle = true
      this.currentImagen = "https://tms-capenh.s3.amazonaws.com/"+record.car_foto1
      var latlng = record.car_gps.split(',')
      var lat = parseFloat(latlng[0])
      var lng = parseFloat(latlng[1])
      this.loadMapa(lat, lng)
    },
    loadMapa(lat, lng){
    loader.load().then(function(google) {

      // Custom Skin & Settings Map Begin
      const myLatlng2 = new google.maps.LatLng(lat, lng); //(23.6, -102.5);
      const mapOptions2 = {
        zoom: 10,
        center: myLatlng2,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      };

      console.log(document.getElementById("customSkinMap"));
      const map2 = new google.maps.Map(
        document.getElementById("customSkinMap"),
        mapOptions2
      );

      const marker2 = new google.maps.Marker({
        position: myLatlng2,
        title: "Custom Skin & Settings Map!"
      });

      marker2.setMap(map2);
      // Custom Skin & Settings Map End
    });
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
  },
  mounted() {


    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      // console.log( JSON.parse(localStorage.getItem('permission')));
      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_ini.actions

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

        console.log(this.permisos);
      this.getRegiones()
      this.getData()
    } else {
       this.$router.push('login');
    }

  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}

.xs{
  min-width: 100px;
  text-justify: auto;
}

.sm{
  min-width: 150px;
  text-justify: auto;
}

.mb{
  min-width: 200px;
  text-justify: auto;
}

.lm{
  min-width: 250px;
  text-justify: auto;
}

.xl{
  min-width: 300px;
  text-justify: auto;
}

.table th {
  text-align: left;
}

.success {
  color: blue;
}

.select_0 {
  padding-right: 5px;
}

.select {
  padding-left: 0px;
  padding-right: 5px;
}

.el-input__icon{
  width: 0px;
}

span.el-range-separator{
  width: 15px;
}

.el-range-input{
  width: 50%;
}

.card-map {
  min-height: 150px;
  .map {
    height: 150px;
    width: 100%;
  }
}
</style>
