<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar Filtro</el-dropdown-item>
              <el-dropdown-item command="filtrar">Filtrar por IDs</el-dropdown-item>
              <el-dropdown-item command="nuevo">Crear Nuevo</el-dropdown-item>
              <el-dropdown-item command="cambiar" v-if="permisos.mass">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="asignar">Re Asignar</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="usuarios.length != 0">Usuarios:</span>
          <el-select v-if="usuarios.length != 0" v-model="usuario" placeholder="Usuarios" size="mini">
            <el-option
              v-for="item in usuarios"
              :key="item.i+'us'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas.length != 0">Rutas:</span>
          <el-select v-if="preventas.length != 0" v-model="pre" placeholder="RUTAS" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i+'pr'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Estatus:
          <el-select v-model="estatus" placeholder="ESTATUS" size="mini">
            <el-option
              v-for="item in status"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Source:
          <el-select v-model="source" placeholder="SOURCE" size="mini">
            <el-option
              v-for="item in sources"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
               
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            class="elevation-1"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @click:row="eventRow"
          >
          <template v-slot:item.valCar_f1="{ item }">
            {{ item.valCar_f1 != '' ? 'Sí' : 'No' }}
          </template>
          <template v-slot:item.valCar_gps="{ item }"> 
            {{ item.valCar_gps != '' ? 'Sí' : 'No' }}
          </template>
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
          <!--<div class="row pt-2 justify-content-between">
            <div class="col-4">
              Registros de {{ start + 1 }} A {{ start + itemsT }} de {{ total }}
            </div>
            <div class="col-6">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                :total-visible="7"
                @input="newPagina($event)"
              ></v-pagination>
            </div>
          </div>-->
      </div>
    </div>

    <!-- modals-->
    <el-dialog
      title="Filtrar Registros"
      :visible.sync="dgFiltrar"
      width="30%">
      <p>Ingrese los Ids</p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Ids separados por coma (,)"
        v-model="idsFiltrar">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgFiltrar = false">Cancelar</el-button>
        <el-button type="primary" @click="filtrarRegistros">Filtrar</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Nuevo Cliente"
      :visible.sync="dgNuevo"
      width="40%">
      <div data-app>
          <v-form ref="nwClient" v-model="nuevoValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-6 pr-2">
            <v-text-field v-model="clNuevo.name" :rules="nameRules" label="Nombre" required ></v-text-field>
            </div>
            <div class="col-6 pl-2">
              <v-text-field v-model="clNuevo.folio" :rules="folioRules" label="Folio" required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <v-text-field v-model="clNuevo.phone" :counter="10"  :rules="phoneRules" label="Teléfono" required ></v-text-field>
            </div>
            <div class="col-6 pl-2">
              <v-text-field v-model="clNuevo.phone2" :counter="10" :rules="phoneRules" label="Teléfono 2" required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <v-select v-model="clNuevo.region" :items="itemsRegiones"
                :rules="[v => !!v || 'Seleccione una Región']"
                item-value="i"
                item-text="d"
                label="Región"
                required
                @input="getGerencias(1)"
              ></v-select>
            </div>
            <div class="col-6 pl-2">
              <v-select v-model="clNuevo.gerencia" :items="itemsGerencias"
                :rules="[v => !!v || 'Seleccione una Gerencia']"
                label="Gerencia"
                item-value="i"
                item-text="d"
                required
                @input="getCedis(1)"
              ></v-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <v-select v-model="clNuevo.cedis" :items="itemsCedis"
                :rules="[v => !!v || 'Seleccione un Cedis']"
                label="Cedis"
                item-value="i"
                item-text="d"
                required
                @input="getPreventa(1)"
              ></v-select>
            </div>
            <div class="col-6 pl-2">
              <v-text-field v-model="clNuevo.ruta" :rules="rutaRules" label="Ruta" required ></v-text-field>
              <!--<v-select v-model="clNuevo.ruta" :items="itemsRutas"
                :rules="[v => !!v || 'Seleccione una Ruta']"
                label="Ruta"
                item-value="i"
                item-text="d"
                required
              ></v-select>-->
            </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="crearNuevo">Enviar Información</el-button>
                </div>
              </div>
      </v-form>
      </div>
    </el-dialog>
    <el-dialog
       title="Cambios Masivos"
       :visible.sync="dgCambios"
       width="30%">
       <el-upload
         name="wnd_upld_xls"
         ref="upMasivos"
         class="upload-demo mb-3"
         :on-change="masivoArchivo"
         :auto-upload="false"
         >
         <el-input name="wnd_upld_act" value="2" style="display:none" ></el-input>
         <!--<el-input name="wnd_upld_uid" value="5"></el-input>-->
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <!--<el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>-->
       <span slot="footer" class="dialog-footer">
         <el-button @click="dgCambios = false">Cancelar</el-button>
         <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
       </span>
     </el-dialog>

   <el-dialog
      title="Re-Asignar Registros"
      :visible.sync="dgAsignar"
      width="30%">
        <div data-app>
          <v-select v-model="callCenter" :items="itemsCallCenter"
            :rules="[v => !!v || 'Seleccione un Call Center']"
            label="Call Center"
            item-value="i"
            item-text="d"
            required
          ></v-select>
          <p>Ingrese los Ids</p>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Ids separados por coma (,)"
            v-model="idsReasignar"
            class="pb-4">
          </el-input>
          <div class="text-right">
            <el-button @click="dgAsignar = false">Cancelar</el-button>
            <el-button type="primary" @click="reAsignar">Asignar</el-button>
          </div>
        </div>
        </el-dialog>
        <el-dialog
          :title="currentClient"
          :visible.sync="dgDetalle"
          top="10px"
          fullscreen>
            <div class="row">
                <div class="col-md-4 col-form-label">
                <p>
                <center><b>Aviso clientes</b></center>
                </p>
                <p>
                1. Responsable del tratamiento de sus datos personales
                </p>
                <p>
                  Grupo Gepp, S.A.P.I. de C.V., (“GEPP”), , persona moral, debidamente constituida conforme a las leyes de la República Mexicana, 
                  con domicilio en Av. Santa Fe No. 485 piso 4, Col. Cruz Manca, México, Ciudad de México, Cuajimalpa de Morelos, CP 05349,
                  es la persona responsable del tratamiento de sus datos personales.
                </p>
                  <center><img :src="currentImagenFirma" alt="" height="200px" /></center>
                  <div class="text-center">
                    <el-button type="primary" @click="descargaContrato">Descargar Contrato</el-button>
                  </div>
                </div>
                <div class="col-md-4 col-form-label text-center">
                  <img :src="currentImagen" alt="" height="500px" />
                </div>
                  <div class="col-md-4">
                      <div id="customSkinMap" class="map" ></div>
                  </div>
            </div>
        </el-dialog>
    <!-- end modals-->

    <el-dialog
      :title="titlePass"
      :visible.sync="dgPass"
      width="30%">
      <p>Contraseña</p>
      <el-input v-model="password" placeholder="Contraseña"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgPass = false">Cancelar</el-button>
        <el-button type="primary" @click="validaPass">Confirmar</el-button>
      </span>
    </el-dialog>

    <v-overlay :value="overlay" z-index="20010">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      usuario: null,
      usuarios: [],
      pre: null,
      preventas: [
        ],
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      source: null,
      sources: [
      { i: -1, d: 'Todos' },
      { i: 0, d: 'App' },
      { i: 1, d: 'Facebook' },
      { i: 2, d: 'Web' },
      { i: 3, d: 'Portal' },
      { i: 4, d: 'Call Center' },
      { i: 5, d: 'Whats' },
      { i: 6, d: 'Transfer' },
      { i: 7, d: 'Lona' },
      { i: 8, d: 'App Promotor' },
      { i: 9, d: 'Socios Comerciales' },
      { i: 10, d: 'Campaña Digital' }
    ],
    estatus: null,
    status: [
      { i: -1, d: 'Todos' },
      //{ i:  1, d: 'Alta' },
      { i:  2, d: 'Rechazado' },
      { i:  3, d: 'Capturado' },
      { i:  6, d: 'Pendiente por validar' },
      { i:  8, d: 'Validado' },
      //{ i:  9, d: 'Progreso' },
      { i:  10, d: 'Pre validado' },
    ],
    dateTimePicker: [],
      desserts: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'valCar_id',
        },
        { text: 'Cartera ID', value: 'car_id', width: 95, sortable: false },
        { text: 'Región', value: 'valCar_reg', width: 100 },
        { text: 'Gerencia', value: 'valCar_gerencia', width: 200, sortable: false },
        { text: 'Cedis', value: 'valCar_cds', width: 200, sortable: false },
        { text: 'Promotor', value: 'valCar_uid', width: 250 },
        { text: 'CallCenter Asignado', value: 'valCar_nom_call', width: 250, sortable: false },
        { text: 'Estatus Validación', value: 'valCar_val_status', width: 130, sortable: false },
        { text: 'Revisado Gepp', value: 'valCar_gepp_rev', width: 130, sortable: false },
        { text: 'Razón Rechazado', value: 'car_mot_inc', width: 130, sortable: false },
        { text: 'Alta', value: 'car_nuevo', sortable: false },
        { text: 'Source', value: 'valCar_sourse', width: 150, sortable: false },
        { text: 'Estatus Abrio', value: 'valCar_status_abrio', sortable: false },
        { text: 'Garrafón de Marca', value: 'valCar_pro_compra', sortable: false },
        { text: 'Marca', value: 'valCar_pro_marca', width: 100, sortable: false },
        { text: 'Garrafones por Semana', value: 'valCar_pro_cuantos', sortable: false },
        { text: 'Esta Interesado', value: 'valCar_pro_interesado', sortable: false },
        { text: 'Folio', value: 'valCar_codigo_verificacion', width: 200, sortable: false },
        { text: 'Intentos llamada', value: 'valCar_intentos', sortable: false },
        { text: 'Ruta', value: 'valCar_rt_prev, sortable: false' },
        { text: 'NUD', value: 'valCar_NUD', sortable: false },
        { text: 'Nombre', value: 'valCar_nombre', width: 300, sortable: false },
        { text: 'GPS', value: 'valCar_gps', sortable: false },
        { text: 'Dirección', value: 'valCar_dir', width: 300, sortable: false },
        { text: 'Num. Ext', value: 'valCar_num_ext', width: 100, sortable: false },
        { text: 'Num. Int', value: 'valCar_num_int', sortable: false },
        { text: 'Colonia', value: 'valCar_col', width: 250, sortable: false },
        { text: 'Calle 1', value: 'valCar_cll_1', width: 200, sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2', width: 200, sortable: false },
        { text: 'C.P', value: 'valCar_cp', sortable: false },
        { text: 'Telefono 1 Celular', value: 'valCar_tel_mot', sortable: false },
        { text: 'Telefono 2 Fijo', value: 'valCar_tel_int', sortable: false },
        { text: 'Comentario', value: 'valCar_comments', width: 250, sortable: false },
        { text: 'Comentario pre-validado', value: 'valCar_com_pre', sortable: false },
        { text: 'Correo', value: 'valCar_email', width: 150, sortable: false },
        { text: 'Foto 1', value: 'valCar_f1', sortable: false },
        { text: 'Firma', value: 'valCar_firma_digital', sortable: false },
        { text: 'Fecha Envío', value: 'valCar_upload_date', sortable: false },
        { text: 'Hora Envío', value: 'valCar_upload_time', sortable: false },
        { text: 'Intento 1', value: 'intento_date_time_1', width: 150, sortable: false },
        { text: 'Intento 2', value: 'intento_date_time_2', width: 150, sortable: false },
        { text: 'Intento 3', value: 'intento_date_time_3', width: 150, sortable: false },
        { text: 'Intento 4', value: 'intento_date_time_4', width: 150, sortable: false },
        { text: 'Intento 5', value: 'intento_date_time_5', width: 150, sortable: false },
        { text: 'Archivo', value: 'valCar_bk', sortable: false }, //valCar_s3_bucket
        { text: 'Fecha Validación', value: 'valCar_tel_rev_date', sortable: false },
        { text: 'Hora Validación', value: 'valCar_tel_rev_time', sortable: false },
        { text: 'Update Massive', value: 'valCar_ms_updt', sortable: false }
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgFiltrar: false,
      idsFiltrar: null,
      dgNuevo: false,
      clNuevo: {
        name: '',
        phone: '',
        phone2: '',
        folio: '',
        oper: 1,
        region: '',
        gerencia: '',
        cedis: '',
        ruta: ''
      },
      nuevoValid: true,
      nameRules: [
        v => !!v || 'Nombre Requerido'
      ],
      folioRules: [
        v => !!v || 'Folio Requerido'
      ],
      rutaRules: [
        v => !!v || 'Rura Requerida'
      ],
      phoneRules: [
        v => (v && v.length >= 10) || 'Escriba un Número valido',
      ],
      dgCambios: false,
      dgAsignar: false,
      //nwRegion: '',
      itemsRegiones: [],
      itemsGerencias: [],
      itemsCedis: [],
      itemsRutas: [],
      resultadoMasivo: '',
      callCenter: '',
      itemsCallCenter: [],
      masvisoFileXls: null,
      dgDetalle: false,
      currentClient: '',
      currentImagen: '',
      currentImagenFirma: '',
      permisos: {},
      idsReasignar: null,
      urlUpMasivos: "",
      titlePass: '',
      dgPass: false,
      password: '',
      currentAction: ''
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'filtrar':
          this.dgFiltrar = true
        break
        case 'nuevo':
          this.dgPass = true
          this.getRegiones(1)
          if(this.$refs.nwClient){
            this.$refs.nwClient.reset()
          }
          this.password = ''
          this.currentAction = 'crear_nuevo'
          this.titlePass = 'Nuevo Cliente'
        break
        case 'cambiar':
          this.dgPass = true
          this.password = ''
          this.currentAction = 'cambios_masivos'
          this.titlePass = 'Cambios Masivos'
        break
        case 'asignar':
          this.getCallCenters()
          this.dgAsignar = true
        break
      }
    },
    rowClass(item) {
      // console.log(item);
      if(item.valCar_val_status == 'Capturado' && item.valCar_gepp_rev == 'NO VALIDADO') { return 'table-primary' }
      if(item.valCar_val_status == 'Capturado' && item.valCar_gepp_rev == 'VALIDADO') { return 'table-info' }
      if(item.valCar_val_status == 'Rechazado' ) { return 'table-danger' }
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    filtrar() {
      this.idsFiltrar = null
      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))

      //console.log(this.usuario);

      this.overlay = true
      let json ={
        c: "carteraFin",
        cmd: "getCartFinData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        opr: 0,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        rta_id: this.pre ? this.pre.join(',') : '',
        nue_id: this.nuevo,
        sourse_Id: this.source,
        userCom: this.usuario,
        sts_id: this.estatus,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        //date_fr: this.dateTimePicker[0],
        //date_to: this.dateTimePicker[1]
      };

      if(this.idsFiltrar){
        json.ids = this.idsFiltrar
      }else{
        json.date_fr = this.dateTimePicker[0]
        json.date_to = this.dateTimePicker[1]
      }


      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data);
        var resp = data.data.rows
        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        this.overlay = false
        //this.pagination.rowsTotal = data.data.results
        //this.isBusy = false
      })
    },
    getRegiones(cd){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        if(typeof(data.data.success) != "undefined" && !data.data.success){
            localStorage.clear()
            this.$router.push('login')
        }else{
          if(cd == 1){
            //this.regiones.splice(0,1)
            this.itemsRegiones = data.data
          }else{
            this.regiones = data.data
            this.regiones.unshift({ i: -1, d: 'TODAS' })
          }
          //this.itemsRegiones.splice(0,1)
        }
        
      })
    },
    getGerencias(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.usuario = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : this.clNuevo.region ? this.clNuevo.region : null
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.itemsGerencias = data.data
        }
      })
    },
    getCedis(sc){

    if(this.gerencia == -1){
        //this.gerencia = null
        this.cedi = null
        this.pre = null
        this.usuario = null
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : this.clNuevo.gerencia ? this.clNuevo.gerencia : null
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.itemsCedis = data.data
        }
      })
    },
    getPreventa(sc){
    if(this.cedi == -1){
        //this.gerencia = null
        //this.cedi = null
        this.pre = null
        this.usuario = null
        //this.gerencias = []
        //this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }
      
      this.getUsuarios()

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc==0) { 
        this.preventas = data.data
        this.preventas.unshift({ i: -1, d: 'TODAS' })
        }else {
          this.itemsRutas = data.data
        }
      })
    },
    getUsuarios(){
      let json ={
        c: "geppValida",
        cmd: "getUserJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        this.usuarios = data.data
        this.usuarios.unshift({ i: -1, d: 'TODOS' })
      })
    },
    descargaFiltro(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : ''
      str += this.estatus ? "&sts_id="+this.estatus : ''
      str += this.source ? "&sourse_Id="+this.source : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = "https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=carteraFin&cmd=getCartFinDataexportlist&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr=0"+str;
    },
    filtrarRegistros(){
      this.getData()
    },
    verificaTel(){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "CarteraFin",
          cmd: "verifiTel",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          Tel: this.clNuevo.phone
        };

        axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
        .then(data=>{
          console.log(data.data);
          if(data.data.success) {
            resolve(true)
          }else {
            resolve(false)
          }
        })
      })
      return respuesta
    },
    async crearNuevo(){
      if(this.$refs.nwClient.validate()){
        this.overlay = true
        var val = await this.verificaTel()
        if(val){
          let json = {
          usuarioid: this.uid,
          data: [
              {
                  a1: this.clNuevo.name,
                  a7: this.clNuevo.phone,//tel,
                  a8: this.clNuevo.phone2,//tel2,
                  h0: "3",
                  h4: this.clNuevo.folio,
                  region: this.clNuevo.region,
                  gerencia: this.clNuevo.gerencia,
                  cedi: this.clNuevo.cedis,
                  ruta: this.clNuevo.ruta
              }
              ]
          }
              // POST
            axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraFin&cmd=validacionInsert&exec=self"
                        +"&hash="+this.hash+"&uid="+this.uid, JSON.stringify(json))
            .then(data=>{
              console.log(data.data);
              if(data.data.success) {
                this.dgNuevo = false
                this.overlay = false
                this.getData()
                  this.$notify({
                    title: '',
                    message: 'Cliente Registrado con éxito.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success',
                    timeout: 4000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }else {
                this.overlay = false
                  this.$notify({
                    title: 'Error',
                    message: 'El cliente no se registro de forma correcta.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'warning',
                    timeout: 4000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }
            })
        }else{
          this.overlay = false
          this.$notify({
            title: 'Error',
            message: 'Se ha superado el número de registros.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      }
    },
    cambiosMasivos(){
      this.overlay = true

      const formData = new FormData();
      formData.append('wnd_upld_xls', this.masvisoFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraFin&cmd=massiveUpdate&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
        //this.dgCambios = false
        this.overlay = false
        if(data.data.success){
            //this.$refs.upMasivos.reset()
            //this.resultadoMasivo = data.data.reg
            this.$notify({
              title: '',
              message: 'Se realizaron los cambios con éxito.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }else{
          this.$notify({
            title: 'Error',
            message: 'Hay un error con el archivo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      })
    },
    reAsignar(){
      this.overlay = true
      let json ={
        c: "CarteraFin",
        cmd: "_reAsignarCallCenter",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        idReasign: this.idsReasignar,
        CallId: this.callCenter
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        this.overlay = false
        this.dgAsignar = false
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    masivoArchivo(file){
      console.log(file.raw);
      this.masvisoFileXls = file.raw
    },
    getCallCenters(){
      let json ={
        c: "CarteraFin",
        cmd: "getCallCenterUsersJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data.data);
        this.itemsCallCenter = data.data
      })
    },
    eventRow(record){
      console.log(record);
      this.currentClient = record.valCar_nombre
      this.dgDetalle = true
      this.currentImagen = "https://tms-capenh.s3.amazonaws.com/"+record.valCar_f1
      this.currentImagenFirma = "https://tms-capenh.s3.amazonaws.com/"+record.valCar_f2
      var latlng = record.valCar_gps.split(',')
      var lat = parseFloat(latlng[0])
      var lng = parseFloat(latlng[1])
      this.loadMapa(lat, lng)
    },
    loadMapa(lat, lng){
    loader.load().then(function(google) {

      // Custom Skin & Settings Map Begin
      const myLatlng2 = new google.maps.LatLng(lat, lng); //(23.6, -102.5);
      const mapOptions2 = {
        zoom: 10,
        center: myLatlng2,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true
      };

      // console.log(document.getElementById("customSkinMap"));
      const map2 = new google.maps.Map(
        document.getElementById("customSkinMap"),
        mapOptions2
      );

      const marker2 = new google.maps.Marker({
        position: myLatlng2,
        title: "Custom Skin & Settings Map!"
      });

      marker2.setMap(map2);
      // Custom Skin & Settings Map End
    });
    },
    descargaContrato(){
      
      window.location = "https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraFin&cmd=downloadContrat&exec=self&uid="+this.uid+"&hash="+this.hash+"&img="+this.currentImagenFirma;
    },
    validaPass(){
      if(this.password == '#admin'){
        this.dgPass = false
          if( this.currentAction == 'crear_nuevo'){
            this.dgNuevo = true
          }else if(this.currentAction == 'cambios_masivos'){
            this.dgCambios = true
          }
      }else{
        this.$notify({
          title: 'Error',
          message: 'Contraseña Incorrecta verifique y vuelva a intentar.',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 4000,
          icon: 'tim-icons icon-bell-55'
        });
      }
    }
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_fin.actions

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

      this.getRegiones(0)
      this.getData()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}


.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   /*font-size: 11px !important;
   height: 20px;*/
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 500px;
    width: 100%;
  }

</style>