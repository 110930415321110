<template>
<div class="content">
  <div class="row">
    <div class="col-2">
          <!--<el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga">Descargar Filtro</el-dropdown-item>
              <el-dropdown-item command="sube">Subir Clientes</el-dropdown-item>
              <el-dropdown-item command="cambia">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="cambiar">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="asignar">Re Asignar</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>-->
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas.length != 0">Ruta:</span>
          <el-select v-if="preventas.length != 0" v-model="preventa" placeholder="PREVENTA" size="mini" >
            <el-option
              v-for="item in preventas"
              :key="item.i+'pr'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span >Usuarios:</span>
          <el-select v-model="user" placeholder="UAURIOS" size="mini" >
            <el-option
              v-for="item in users"
              :key="item.i+'us'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="date"
              placeholder="Seleccione"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
      <card type="plain">
        <!--<h4 slot="header" class="card-title">Satellite Map</h4>-->
        <div id="satelliteMap" class="map map-big"></div>
      </card>
    </div>
    <!--<div class="col-md-6">
      <card type="plain">
        <h4 slot="header" class="card-title">Regular Map</h4>
        <div id="regularMap" class="map"></div>
      </card>
    </div>
    <div class="col-md-6">
      <card type="plain">
        <h4 slot="header" class="card-title">Custom Skin & Settings Map</h4>
        <div id="customSkinMap" class="map"></div>
      </card>
    </div>-->
  </div>
</div>
</template>
<script>
import { API_KEY } from "../Maps/API_KEY";
import { Loader, LoaderOptions } from "google-maps";
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
const loader = new Loader(API_KEY);
import axios from 'axios';
var allPol = [];
export default {
 components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      arrTmp:[],
      arr: [],
      refMap: null,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      dateTimePicker: '',
      preventa: null,
      preventas:[],
      users: [],
      user: null,
      uid: null,
      hash: null
    }
  },
  methods: {
    filtrar(){
      this.getPoints();
    },
    getPoints(){
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

      var self = this;
      let json ={
        c: "map",
        cmd: "getPoints",
        exec: "self",
        opr: 0,
        reg_id: this.region ? this.region : '',
        ger_id: this.gerencia ? this.gerencia : '',
        cds_id: this.cedi ? this.cedi : '',
        date_fr: this.dateTimePicker ? this.dateTimePicker : date,
        date_to: this.dateTimePicker ? this.dateTimePicker : date,
        user: this.user && this.user != -1 ? this.user : ''
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data.data);
        //self.arr = data.data;
        self.arrTmp = data.data;

        this.loadMap()
      })
    },//endGet   
    loadMap(){
          var self = this;
    loader.load().then(function(google) {
      
      // Satellite Map Begin
      const myLatlng = new window.google.maps.LatLng(19.42847, -99.12766);
      const mapOptions = {
        zoom: 10,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        center: myLatlng,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
      };

      self.refMap = new window.google.maps.Map(
        document.getElementById("satelliteMap"),
        mapOptions
      );
      //self.testPuntos()
      self.generatepoints()


    });
    },
    generatepoints(){
      console.log(this.arrTmp);
      var latBounds = null;
      var lngBounds = null;
      for(var z in this.arrTmp){
        //var arrDat = [];
        /*for(var j in this.arrTmp[z]){
          //const arrTmp = [];
          var objTm = {
            lat: parseFloat(this.arrTmp[z][j].tr_lat),
            lng: parseFloat(this.arrTmp[z][j].tr_lng),
          }
          //console.log();
          arrDat.push(objTm);
                    
        }*/
        //arrDat.push()
        ///console.log(this.arrTmp[z]['points'][0][0]);
        const latSt = this.arrTmp[z]['points'][0][0].lat;
        const lngSt = this.arrTmp[z]['points'][0][0].lng;
        const idx = this.arrTmp[z]['points'].length-1;
        const idx1 = this.arrTmp[z]['points'][idx].length-1;
        //console.log(latSt + " "+lngSt);
        const latNd = this.arrTmp[z]['points'][idx][idx1].lat;
        const lngNd = this.arrTmp[z]['points'][idx][idx1].lng;
        const myLatlngSt = new window.google.maps.LatLng(latSt, lngSt);
        const myLatlngNd = new window.google.maps.LatLng(latNd, lngNd);
        latBounds = myLatlngSt;
        lngBounds = myLatlngNd;

        const markerSt = new window.google.maps.Marker({
          position: myLatlngSt,
          title: this.arrTmp[z].username+" , "+this.arrTmp[z].pointst
        });
        markerSt.setMap(this.refMap);

        const markerNd = new window.google.maps.Marker({
          position: myLatlngNd,
          title: this.arrTmp[z].username+" , "+this.arrTmp[z].pointnd
        });
        markerNd.setMap(this.refMap);
        //console.log(this.arrTmp[z].points);
        this.renderPolyline(this.arrTmp[z]['points']);
        //console.log(arrDat);

        //this.arr.push(arrDat);
      }
      //console.log();
      var bounds = new window.google.maps.LatLngBounds();
      bounds.extend(latBounds);
      bounds.extend(lngBounds);
      this.refMap.fitBounds(bounds);
      //this.renderPolyline()
    },
    renderPolyline(arrJson){
      //var arr = [];
      //console.log(arrJson);
      var color = this.random_hex_color_code();
      	var allArr = [];
          for(var i = 0; i < arrJson.length ; i++){
            for(var c = 0; c < arrJson[i].length; c++){
              const myLatlngXd = new window.google.maps.LatLng(arrJson[i][c].lat, arrJson[i][c].lng);
                const markerXd = new window.google.maps.Marker({
                  position: myLatlngXd,
                  icon: 'img/traking_point_b.png',
                  title: arrJson[i][c].time
                });
              markerXd.setMap(this.refMap);
            }
           //console.log(arrJson[i]);
            
            new window.google.maps.Polyline({
                path: arrJson[i],
                geodesic: true,
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: this.refMap
              });
          }
    },
    testPuntos(){
      var color = this.random_hex_color_code();
           var puntos = [
            [
                {
                    "lat": 19.0847435,
                    "lng": -98.2256802
                },
                {
                    "lat": 19.0847692,
                    "lng": -98.2256937
                },
                {
                    "lat": 19.0847575,
                    "lng": -98.2256948
                }],
                [
                {
                    "lat": 19.084846,
                    "lng": -98.2257953
                },
                {
                    "lat": 19.0848517,
                    "lng": -98.2258072
                },
                {
                    "lat": 19.0848373,
                    "lng": -98.225839
                },
                {
                    "lat": 19.0848444,
                    "lng": -98.2258603
                },
                {
                    "lat": 19.0848448,
                    "lng": -98.2258605
                }
                ]
                ];
           //console.log(puntos);
          for(var i = 0; i < puntos.length ; i++){
                  new window.google.maps.Polyline({
                      path: puntos[i],
                      geodesic: true,
                      strokeColor: color,
                      strokeOpacity: 1.0,
                      strokeWeight: 2,
                      map: this.refMap
                    });
          }

    },
    random_hex_color_code(){
      let n = (Math.random() * 0xfffff * 1000000).toString(16);
      return '#' + n.slice(0, 6);
    },
    getRegiones(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        if(typeof(data.data.success) != "undefined" && !data.data.success){
            localStorage.clear()
            this.$router.push('login')
        }else{
        //console.log(data);
          this.regiones = data.data
          //this.itemsRegiones = data.data
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.itemsRegiones.splice(0,1)
        }
          
        
      })
    },
    getGerencias(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : this.clNuevo.region ? this.clNuevo.region : null
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.itemsGerencias = data.data
        }
      })
    },
    getCedis(sc){
      if(this.gerencia == -1){
        this.cedi = null
        this.cedis = []
        this.preventa = null
        this.preventas = []
        return false
      }
      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : this.clNuevo.gerencia ? this.clNuevo.gerencia : null
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.itemsCedis = data.data
        }
      })
    },
    getPreventa(sc){

    if(this.cedi == -1){
        //this.gerencia = null
        //this.cedi = null
        this.preventa = null
        //this.gerencias = []
        //this.cedis = []
        this.preventas = []
        //this.usuarios = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc==0) { 
        this.preventas = data.data
        this.preventas.unshift({ i: 'all', d: 'TODAS' })
        }else {
          this.itemsRutas = data.data
        }
      })
    },
    getUsers() {
      //console.log(this.pre.join(','))
      //this.overlay = true
      let json ={
        c: "users",
        cmd: "GetUsers",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php", {params: json})
      .then(data=>{
        console.log(data);
        var resp = data.data
        this.users = resp.data
        this.users.unshift({ i: -1, d: 'TODOS' })
        this.hash = localStorage.getItem('key')
        //this.pagination.rowsTotal = data.data.results
        //this.isBusy = false
      })
    }
  },
  mounted() {
    //this.loadMap()
    this.uid = localStorage.getItem('uid')
    this.hash = localStorage.getItem('key')
    this.getRegiones();
    this.getPoints();
    this.getUsers();
   // this.loadMap();


  }
};
</script>
<style lang="scss">
.card-map {
  min-height: 450px;
  .map {
    height: 400px;
    width: 100%;
  }
}
</style>
